import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link as ScrollerLink } from 'react-scroll'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from 'gbimage-bridge'
import RenderForm from 'gatsby-plugin-cloudofertas'
import * as styles from './styles/formulario.module.scss'
// components
import Layout from '../components/Layout'

// styles
import './styles/index.scss'

// icons
import iconCheck from '../images/Beneficios/iconCheck.svg'
import supermercados from '../images/supermercados.svg'
import customizavel from '../images/customizavel.svg'
import lojas from '../images/lojas.svg'
import farmacias from '../images/farmacias.svg'
import verificado from '../images/Banner/verificado.svg'
import coracao from '../images/Banner/coracao.svg'
import cesta from '../images/Banner/cesta.svg'
import logoDia from '../images/Clientes/dia.svg'
import logoDrogaleste from '../images/Clientes/drogaleste.svg'
import logoNovaFarma from '../images/Clientes/novafarma.svg'
import okIcon from '../images/icon/ok-icon.svg'

// import Axios from 'axios'

const Pagina = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <html lang="pt" />
      <title>CloudOfertas</title>
      <meta name="title" content='CloudOfertas' />
      <meta name="description" content='O CloudOfertas é uma plataforma customizável para exposição de ofertas. O sistema ainda permite integração com os principais meios de pagamentos. Conheça!' />
      <meta name="keywords" content='supermercados, ofertas, descontos' />
      <meta name="author" content="CloudDog" />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content='CloudOfertas' />
      <meta property="og:type" content="article" />
      <meta property="og:url" content='https://www.cloudofertas.com.br/' />
      {/* <meta
        property="og:image"
        content={`https://www.cloudofertas.com.br${pagina.card.localFile.publicURL}`}
      /> */}
      <meta property="og:description" content='supermercados, ofertas, descontos' />
    </Helmet>
  )
}

const Banner = (props) => {
  const image = getImage(props.bannerDesktop)
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      {...bgImage}
      className="d-lg-block d-none">
      <div style={{ minHeight: 510 }}>
        {/* <section className="d-lg-block d-none"> */}
        <div className="container h-100">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-4 justify-content-end d-flex align-items-center h-100">
              <div>
                <div className="nota-custom">
                  <div className="h-100 d-flex align-items-start pt-2">
                    <img src={verificado} alt="Icone de verificado" className="pr-2" width="28" height="20" />
                  </div>
                  <div>
                    <p className="Montserrat-Black text-orange text-uppercase mb-0">facilidade de uso</p>
                    <p className="Montserrat text-uppercase mb-0 paragrafoBanner">No celular e em qualquer lugar</p>
                  </div>
                </div>
                <div className="nota-custom">
                  <div className="h-100 d-flex align-items-start pt-2">
                    <img src={coracao} alt="Icone de coração" className="pr-2" width="28" height="20" />
                  </div>
                  <div>
                    <p className="Montserrat-Black text-orange text-uppercase mb-0">lista de favoritos</p>
                    <p className="Montserrat text-uppercase mb-0 paragrafoBanner">Para facilitar as compras do cliente</p>
                  </div>
                </div>
                <div className="nota-custom">
                  <div className="h-100 d-flex align-items-start pt-2">
                    <img src={cesta} alt="Icone de cesta" className="pr-2" width="28" height="20" />
                  </div>
                  <div>
                    <p className="Montserrat-Black text-orange text-uppercase mb-0">opção de delivery</p>
                    <p className="Montserrat text-uppercase mb-0 paragrafoBanner">O cliente vê as ofertas e compra sem sair de casa</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4">
              <GatsbyImage image={props.celular} alt="Celular banner" />
            </div>
            <div className="col-xl-4 col-lg-4 text-left align-items-center">
              <h2 className="text-white Montserrat-Black text-shadow">solução de Ofertas definitiva para você e seus clientes.</h2>
              <h2 className="text-white Montserrat-Black text-shadow mt-4">ofertas customizadas por loja.</h2>
            </div>
          </div>
        </div>
        {/* </section> */}
      </div>
    </BackgroundImage>

  )
}

const BannerMobile = (props) => {
  return (
    <div id="sectionTopo">
      <section className="d-lg-none d-block bannerMobile">
        <div className="position-relative">
          <div className="hero w-100 h-100 position-absolute">
            <div className="container h-100">
              <div className="row pt-4 h-100">
                <div className="col-12">
                  <h2 className="text-white Montserrat-Black text-shadow">solução de Ofertas definitiva para você e seus clientes.</h2>
                  <h2 className="text-white Montserrat-Black text-shadow mt-4">ofertas customizadas por loja.</h2>
                </div>
                <div className="col-8 d-flex align-items-end pb-4">
                  <div className="above">
                    <div className="nota-custom">
                      <div className="d-flex align-items-start">
                        <img src={verificado} alt="Icone de verificado" className="pr-2" width="28" height="20" />
                      </div>
                      <div>
                        <p className="Montserrat-Black text-orange text-uppercase mb-0">facilidade de uso</p>
                      </div>
                    </div>
                    <div className="nota-custom">
                      <div className="d-flex align-items-start">
                        <img src={coracao} alt="Icone de coração" className="pr-2" width="28" height="20" />
                      </div>
                      <div>
                        <p className="Montserrat-Black text-orange text-uppercase mb-0">lista de favoritos</p>
                      </div>
                    </div>
                    <div className="nota-custom">
                      <div className="d-flex align-items-start">
                        <img src={cesta} alt="Icone de cesta" className="pr-2" width="28" height="20" />
                      </div>
                      <div>
                        <p className="Montserrat-Black text-orange text-uppercase mb-0">opção de delivery</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="position-absolute divCelular">
                  <GatsbyImage
                    image={props.celularMobile}
                    alt="Celular banner"
                    className="celularBanner" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-100">
            <GatsbyImage image={props.bannerMobile} alt="Banner" />
          </div>
        </div>
      </section>
    </div>
  )
}

const Beneficios = ({ data }) => {
  return (
    <div id="sectionBeneficios">
      <div className='container-fluid bg-orange text-white pt-4 pb-4'>
        <div className='container pt-4 pb-4'>
          <div className='row'>
            <div className='col-12 mb-4'>
              <h2 className='Montserrat'>conheça os</h2>
              <h2 className='Montserrat-Black'>benefícios da plataforma</h2>
            </div>
            <div className='col-12 d-flex mb-4'>
              <div>
                <img src={iconCheck} alt='Icone de marcado' width="25" height="25" />
              </div>
              <p className='m-0 pl-3'>Seleção de Lojas por <span className='Montserrat-Black'>Geolocalização</span> (mais próxima ou escolha manual).</p>
            </div>
            <div className='col-12 d-flex mb-4'>
              <div>
                <img src={iconCheck} alt='Icone de marcado' width="25" height="25" />
              </div>
              <p className='m-0 pl-3'><span className='Montserrat-Black'>Ofertas Customizadas por Lojas:</span> Utilize os tarifários e o controle de entrada e saída para ativar campanhas de ofertas ao longo da semana. Promova promoções de forma a aproveitar o hábito de compras de seus consumidores.</p>
            </div>
            <div className='col-12 d-flex mb-4'>
              <div>
                <img src={iconCheck} alt='Icone de marcado' width="25" height="25" />
              </div>
              <p className='m-0 pl-3'>Interação do cliente com as ofertas, como  <span className='Montserrat-Black'>“Favoritos”</span> para posterior visualização nas lojas ou redirecionamento para o portal de vendas da empresa <span className='Montserrat-Black'>(Delivery)</span>.</p>
            </div>
            <div className='col-12 d-flex mb-4'>
              <div>
                <img src={iconCheck} alt='Icone de marcado' width="25" height="25" />
              </div>
              <p className='m-0 pl-3'><span className='Montserrat-Black'>Baixo consumo de internet</span> (otimizado para usar menos de 1mb por tabloide).</p>
            </div>
            <div className='col-12 d-flex mb-4'>
              <div>
                <img src={iconCheck} alt='Icone de marcado' width="25" height="25" />
              </div>
              <p className='m-0 pl-3'>Disparo de <span className='Montserrat-Black'>Push Notifications</span> informando as novas promoções para os dispositivos dos clientes cadastrados.</p>
            </div>
            <div className='col-12 d-flex mb-4'>
              <div>
                <img src={iconCheck} alt='Icone de marcado' width="25" height="25" />
              </div>
              <p className='m-0 pl-3'><span className='Montserrat-Black'>Integração com os principais meios de pagamentos </span> para que você possa usar no e-commerce da sua empresa.</p>
            </div>
            <div className='col-12 d-flex mb-4'>
              <div>
                <img src={iconCheck} alt='Icone de marcado' width="25" height="25" />
              </div>
              <p className='m-0 pl-3'><span className='Montserrat-Black'>Catálogo de produtos </span> para administração de ofertas no seu e-commerce.</p>
            </div>
            <div className='col-12 d-flex'>
              <div>
                <img src={iconCheck} alt='Icone de marcado' width="25" height="25" />
              </div>
              <p className='m-0 pl-3'><span className='Montserrat-Black'>Cupons e Parcerias: </span> o CloudOfertas possibilita que seus parceiros indiquem seus produtos por meio da plataforma e envio de cupons podendo adicionar regras específicas.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='container pb-4 pt-4'>
        <div className='row'>
          <div className="col-12 pt-4 pb-4">
            <h2 className='Montserrat'>Exemplo do painel administrativo</h2>
            <h2 className='Montserrat-Black'>cloudofertas</h2>
            <GatsbyImage
              image={data.painelAdm.childImageSharp.gatsbyImageData}
              alt="Imagem do painel administrativo" />
            <p>O painel é feito de acordo com a sua necessidade, conforme a opção escolhida.</p>
          </div>
        </div>
      </div>
      <div className='container mt-4 mb-4'>
        <div className='row mb-4'>
          <div className='col-12'>
            <h2 className='Montserrat'>geolocalização</h2>
            <h2 className='Montserrat-Black'>de lojas</h2>
            <p>Essa funcionalidade oferece para o cliente 2 opções para ver a localização da loja que ele deseja. Além de ser um canal para o tabloide digital, ele ainda mostra informações específicas sobre a loja como: endereço, horários de atendimento, como chegar, serviços adicionais e telefones para contato.</p>
            <p>Exemplo no site <a className='linkStyles' href='https://www.drogariasnovafarma.com.br/lojas/' target="_blank" without rel="noreferrer">drogariasnovafarma.com.br/lojas/</a>:</p>
            <GatsbyImage
              image={data.geolocalizacaoImage.childImageSharp.gatsbyImageData}
              alt='Imagem do geolocalizador' />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <h2 className='Montserrat'>visualização do</h2>
            <h2 className='Montserrat-Black'>tabloide digital</h2>
            <p>Exemplo no site <a className='linkStyles' href='https://www.dia.com.br/' target="_blank" without rel="noreferrer">dia.com.br</a>:</p>
            <GatsbyImage
              image={data.tabloideImage.childImageSharp.gatsbyImageData}
              alt='Imagem do tabloide digital' />
          </div>
        </div>
      </div>
      <div className='container mt-4 mb-4'>
        <div className='row mb-4'>
          <div className='col-12'>
            <h2 className='Montserrat'>Integração com os principais </h2>
            <h2 className='Montserrat-Black'>sistemas de pagamentos</h2>
            <p>Visualização da Entrada de Pedidos.</p>
            <GatsbyImage
              image={data.SistemaDePagamento.childImageSharp.gatsbyImageData}
              alt='Imagem do tabloide digital' />
          </div>
        </div>
      </div>
    </div>
  )
}

const SectionPlataforma = ({ data }) => {
  return (
    <div className='container pb-5 pt-5' id="sectionPlataforma">
      <div className='row'>
        <div className='col-12'>
          <h2 className='Montserrat'>Plataforma de E-Commerce para</h2>
          <h2 className='Montserrat-Black'> Supermercados integrada ao Tabloide de Ofertas</h2>
          <p>
             O CloudOfertas foi desenvolvido pela <a className='linkStyles' target='_blank' without rel="noreferrer" href='https://www.clouddog.com.br/'>CloudDog</a>. A plataforma possui recursos para atender à diversas empresas do segmento de varejo e auxiliando na exposição dos produtos e ofertas.
          </p>
          <p>
             Com o CloudOfertas, você pode publicar as ofertas seguindo o mesmo tipo de identidade do tabloide impresso ou se desejar, pode ser montado conforme a necessidade do seu negócio.
          </p>
        </div>
      </div>
    </div>
  )
}

const Clientes = () => {
  return (
    <div className='container pb-4' id="sectionClientes">
      <div className='row'>
        <div className='col-12'>
          <h2 className='Montserrat'>clientes que usam o</h2>
          <h2 className='Montserrat-Black'>cloudofertas</h2>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-lg-3 col-4'>
          <img src={logoDia} className='iconesClientes' alt="Logo do Dia" width="200" height='200'/>
        </div>
        <div className='col-lg-3 col-4'>
          <img src={logoDrogaleste} className='iconesClientes' alt="Logo da Drogaleste" width="200" height='200'/>
        </div>
        <div className='col-lg-3 col-4'>
          <img src={logoNovaFarma} className='iconesClientes' alt="Logo da Novafarma" width="200" height='200'/>
        </div>
      </div>
    </div>
  )
}

const Customizavel = () => {
  return (
    <div className="bg-gray pb-5 pt-4">
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-12 pb-4'>
            <h2 className='Montserrat'>customizável</h2>
            <h2 className='Montserrat-Black'>para lojas de qualquer segmento</h2>
          </div>
          <div className="col-lg-auto d-flex justify-content-center">
            <div>
              <div className="custom-circle justify-content-center d-flex align-items-center">
                <img src={supermercados} alt="Icone de Supermercados" className="pt-3 pb-3" width="90" height="122" />
              </div>
              <div className="d-flex justify-content-center pt-lg-4 pt-1 pb-lg-0 pb-4">
                <p className="mb-0">Supermercados</p>
              </div>
            </div>
          </div>
          <div className="col-lg-auto d-flex justify-content-center">
            <div>
              <div className="custom-circle justify-content-center d-flex align-items-center">
                <img src={farmacias} alt="Icone de Supermercados" className="pt-3 pb-3" width="90" height="122" />
              </div>
              <div className="d-flex justify-content-center pt-lg-4 pt-1 pb-lg-0 pb-4">
                <p className="mb-0">Farmácias</p>
              </div>
            </div>
          </div>
          <div className="col-lg-auto d-flex justify-content-center">
            <div>
              <div className="custom-circle justify-content-center d-flex align-items-center">
                <img src={lojas} alt="Icone de Supermercados" className="pt-3 pb-3" width="90" height="122" />
              </div>
              <div className="d-flex justify-content-center pt-lg-4 pt-1 pb-lg-0 pb-4">
                <p className="mb-0">Lojas em Geral</p>
              </div>
            </div>
          </div>
          <div className="col-lg-auto d-flex justify-content-center">
            <div>
              <div className="custom-circle justify-content-center d-flex align-items-center">
                <img src={customizavel} alt="Icone de Supermercados" className="pt-3 pb-3" width="90" height="122" />
              </div>
              <div className="d-flex justify-content-center pt-lg-4 pt-1 pb-lg-0 pb-4">
                <p className="mb-0">Customizável</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SoliciteOrcamento = ({ data }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false)

  // console.log(data)
  return (
    <section className='container-fluid' id='sectionOrcamento'>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            {submitSuccess === false &&
              <>
                <h2 className='Montserrat'>Solicite um</h2>
                <h2 className='Montserrat-Black'>Orçamento</h2>
              </>
            }
          </div>
        </div>
      </div>
      { data && submitSuccess === false &&
        <>
          <RenderForm
            formData={data}
            setSuccessSubmit={setSubmitSuccess}
            containerStyle='container'
            rowStyle='row'
            inputStyle={`${styles.inputStyle} form-error mt-4`}
            placeholder={true}
            btnContainerStyle='d-flex align-items-end col-12 pt-4'
            btnStyle={`${styles.btn} w-100`}
            btnName='Solicitar Um Orçamento'
            btnLoaderContainer={styles.loaderStyle}
            btnLoaderColor='white'
            btnLoaderLabel='Processando...'
            btnLoaderSize={24}
            errorLabel='bottom'
          />
        </>
      }
      {submitSuccess &&
        <section className='container-fluid  m-0 pt-3'>
          <section className="container p-lg-5">
            <div className="row justify-content-center">
              <h3 className='Montserrat-Black'>
                <img
                  src={okIcon}
                  alt="Ícone indicando que a mensagem foi enviada com sucesso!"
                  width='80'
                  height='80'
                  className="p-0 mr-2"
                />
                Mensagem enviada com sucesso!
              </h3>
            </div>
            <div className="row justify-content-center">
              <p>Por favor, aguarde. Entraremos em contato em breve.</p>
            </div>
            <div className="row justify-content-center">
              <ScrollerLink
                spy={true}
                smooth={true}
                duration={900}
                to="top"
                className="btn btn-lg btn-outline-dark"
              >Voltar para o início
              </ScrollerLink>
            </div>
          </section>
        </section>
      }
    </section>
  )
}

const Index = ({ data }) => {
  return (
    <Layout>
      <Pagina />
      <Banner bannerDesktop={data.bannerOfertas} celular={data.celular.childImageSharp.gatsbyImageData}/>
      <BannerMobile bannerMobile={data.bannerOfertasMobile.childImageSharp.gatsbyImageData} celularMobile={data.celularMobile.childImageSharp.gatsbyImageData}/>
      <Customizavel />
      <SectionPlataforma data={data}/>
      <Beneficios data={data}/>
      <Clientes data={data}/>
      <SoliciteOrcamento data={data.Solicite} />
    </Layout>
  )
}

export default Index

export const query = graphql`{
  geolocalizacaoImage: file(relativePath: {eq: "Beneficios/geolocalizador.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  painelAdm: file(relativePath: {eq: "Plataforma/painelAdm.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  tabloideImage: file(relativePath: {eq: "Beneficios/tabloide.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  SistemaDePagamento: file(relativePath: {eq: "Beneficios/SistemaDePagamento.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerOfertas: file(relativePath: {eq: "Banner/bannerOfertas.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerOfertasMobile: file(relativePath: {eq: "Banner/bannerOfertasMob.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 414, height: 666, layout: CONSTRAINED)
    }
  }
  celular: file(relativePath: {eq: "Banner/celular.png"}) {
    childImageSharp {
      gatsbyImageData(
        quality: 100
        width: 248
        height: 524
        placeholder: NONE
        layout: FIXED
      )
    }
  }
  celularMobile: file(relativePath: {eq: "Banner/celularMobile.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 207, height: 435, layout: FIXED)
    }
  }
  Solicite:cloudOfertasForm(name: {eq: "SOLICITE_UM_ORCAMENTO"}) {
    id
    name
    formTypeField {
      name
      type
      required
      style
      length
      label
      mask
    }
  }
}
`
